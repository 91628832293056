import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@Redux/store';
import { UserData } from '@Basic/object/AuthType';
import { dateSlice } from './dateSlice';

export function useDate() {
  const { dateTime } = useSelector((state: RootState) => state.dateTime);
  const dispatch = useDispatch();

  //   const setToken = (_token: string | undefined) => dispatch(dateSlice.actions.setToken(_token));
  //   const setUserName = (_userName: string | undefined) => dispatch(dateSlice.actions.setUserName(_userName));

  //   const setUserData = (_userData: UserData | undefined) => dispatch(dateSlice.actions.setUserData(_userData));
  //   const logout = () => {
  //     dispatch(dateSlice.actions.setToken(undefined));
  //     dispatch(dateSlice.actions.setUserData(undefined));
  //   };

  //   const isActiveTimeout = () => {
  //     const diff = new Date().getTime() - lastActiveTime;
  //     console.log(`diff = ${diff}`);
  //     return diff > 1000 * 60 * 15;
  //   };
  //   const updateLastActiveTime = () => {
  //     dispatch(dateSlice.actions.setLastActiveTime(new Date().getTime()));
  //   };
  const setDateTime = (_date: Date) => {
    dispatch(dateSlice.actions.setDateTime(_date));
  };

  return {
    // token,
    // setToken,
    // userName,
    // setUserName,
    // userData,
    // setUserData,
    // logout,
    // isActiveTimeout,
    // updateLastActiveTime,
    setDateTime,
    dateTime,
  };
}
